import React from 'react'
import { Top, Footer, Loading, Menu } from '@src/share-component'
import '@src/styles/common.css'
import { useIntl } from "gatsby-plugin-react-intl"

import { Meta } from '@src/share-component'
const Solutions = () => {

  const intl = useIntl()
  return <React.Fragment>
    <Meta
      title = { intl.formatMessage({ id : "solutions-title" }) }
      keywords = { intl.formatMessage({ id : "solutions-keywords" }) }
      description = { intl.formatMessage({ id : "solutions-description" }) }
      slug = "/solutions/"
    />
    <Loading/>
    <div class = 'page'>
      <Menu/>
      <Top/>
      <Footer/>
  </div>
  </React.Fragment>

}



export default Solutions
